const lightKey = 'light';
let darkMode = !localStorage.getItem(lightKey) || localStorage.getItem(lightKey) === 'false';

if (!darkMode) {
    document.documentElement.setAttribute(lightKey, lightKey)
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelector('nav ul li a')?.addEventListener('click', (evt) : void => {
        darkMode = !darkMode;

        if (darkMode) {
            localStorage.setItem(lightKey, 'false');
            document.documentElement.removeAttribute(lightKey)
        } else {
            localStorage.setItem(lightKey, 'true');
            document.documentElement.setAttribute(lightKey, lightKey)
        }
        evt.preventDefault()
    })
});
